import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styles from './index.module.sass'
import stylesTeam from './team.module.sass'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
   faLongArrowAltRight,
   // faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'

import { faUser, faHandshake } from '@fortawesome/free-regular-svg-icons'

import Layout from '../components/layout'
import SEO from '../components/seo'

// import Banner from '../components/banner'
import Service from '../components/service'
import TeamMember from '../components/teamMemberPageComponent'
import Contact from '../components/contact'

import separator from '../assets/images/separator.svg'

// Award Issuer Logos
import barrons from '../assets/images/rankings/barrons.svg'
import financialtimes from '../assets/images/rankings/financialtimes.svg'
import forbes from '../assets/images/rankings/forbes.svg'

// Strategy Graphics
import strategyDiscover from '../assets/images/strategy/discover.svg'
import strategyAnalyze from '../assets/images/strategy/analyze.svg'
import strategyDevelop from '../assets/images/strategy/develop.svg'
import strategyImplement from '../assets/images/strategy/implement.svg'
import strategyMonitor from '../assets/images/strategy/monitor.svg'

import familiesAssisted from '../assets/images/stats/families.svg'
import assets from '../assets/images/stats/assets.svg'
import percentage from '../assets/images/stats/percentage.svg'
import user from '../assets/images/stats/user.svg'
import spc from '../assets/images/stats/spc.svg'
import certification from '../assets/images/stats/certification.svg'

var utcnow = Date.now()
// var live = utcnow >= 1587159000000 && utcnow <= 1587162600000 ? 1 : 0
var ended = utcnow > 1587162600000

const IndexPage = ({ data }) => (
   <Layout>
      <SEO
         title="Finance on a Human Level®"
         keywords={[`finance`, `advisor`, `spc`, `retirement`, `tax`]}
      />
      <section
         className={['section', styles.section, styles.section__hero].join(
            ' '
         )}>
         <div className={styles.hero__background} />
         <div className={styles.hero__content}>
            <div className={styles.hero__left}>
               <h1 className={styles.hero__title}>
                  We're more than a financial planning firm.
               </h1>
               <p>
                  <b>Human-Centric Wealth Management&trade;</b>: Equipped with a
                  specialized, multi-disciplinary, tax-integrated
                  <Link to="/legal/disclaimers#tax">*</Link> team, we are ready
                  to provide simple solutions to complex financial problems, on
                  a human level.
               </p>
               <div
                  className={styles.hero__actions}
                  dangerouslySetInnerHTML={{
                     __html: `
                  <a
                     href="#about"
                     class="button button--secondary button--large button--rounded button--has-icon">
                     Learn More <span>&rarr;</span>
                  </a>
                  <a
                     href="#advisors"
                     onClick="LC_API.open_chat_window();return false;"
                     class="button button--white button--clear button--bordered button--has-icon">
                     Chat with an Advisor <span>&rarr;</span>
                  </a>
               `,
                  }}></div>
            </div>
         </div>
      </section>

      <section
         className={[styles.section, styles.section__about].join(' ')}
         id="about">
         <div className={styles.about___left}>
            <div
               className={['section--header', styles.section__header].join(
                  ' '
               )}>
               <h2
                  className={['section--title', styles.section__title].join(
                     ' '
                  )}>
                  About Us
               </h2>
            </div>
            <div className={styles.about__content}>
               <p>
                  We are honored to be among one of the oldest human-centric,
                  multi-disciplinary, tax-integrated
                  <Link to="/legal/disclaimers#tax">*</Link>, white glove
                  investment management and financial planning firms in the
                  country. While our financial planning and tax services extend
                  nationwide, our financial roots are deeply ingrained
                  throughout the Metropolitan Washington, D.C. landscape.
               </p>
               <p>
                  We are pleased to have assisted over 2,000 families pursue
                  their unique wealth management, estate and tax planning goals,
                  and continue to share our financial vision, wisdom, and tax
                  experience with a multitude of diverse clients from CEOs and
                  private business groups to foundations, attorneys, physicians,
                  executives, entrepreneurs, independent professionals, federal
                  and state government employees, military families, health care
                  professionals, architects, builders, parents, grandparents,
                  widows, widowers, retirees, unmarried couples, as well as
                  budding young families. We have also worked with clients,
                  their children, and, in some cases, even their grandchildren.
               </p>
               <p>
                  Our investment programs are individually designed to help
                  address your financial needs, while empowering you with the
                  appropriate knowledge necessary to make long term investment
                  decisions. Our "Finance on a Human Level<sup>&reg;</sup>"
                  planning program will help guide you on multiple levels of
                  financial interest, while integrating estate and tax
                  provisions into your financial program, through invaluable
                  resources provided by our CPA
                  <Link to="/legal/disclaimers#tax">*</Link> advisory team.
               </p>
               <p>
                  We look forward to hearing from you and assisting with your
                  financial needs.
               </p>
            </div>
            <div className={styles.section__cta}>
               <Link
                  to="/"
                  className="button button--primary button--large button--rounded button--shadow button--has-icon">
                  Let Us Guide You <span>&rarr;</span>
               </Link>
            </div>
         </div>
         <div className={styles.about___right}>
            <div
               className={['section--header', styles.section__header].join(
                  ' '
               )}>
               <h2
                  className={['section--title', styles.section__title].join(
                     ' '
                  )}>
                  Our Strategy
               </h2>
            </div>
            <div className={styles.strategy__content}>
               <div className={styles.strategy}>
                  <div className={styles.strategy__image_container}>
                     <img
                        src={strategyDiscover}
                        className={styles.strategy__image}
                        draggable="false"
                        alt=""
                     />
                  </div>
                  <div className={styles.strategy__content}>
                     <h3 className={styles.strategy__title}>Discover</h3>
                     <p>
                        Our planning process begins with a thorough discussion
                        of your current financial situation and a complete
                        understanding of your lifetime goals.
                     </p>
                  </div>
               </div>
               <div className={styles.strategy}>
                  <div className={styles.strategy__image_container}>
                     <img
                        src={strategyAnalyze}
                        className={styles.strategy__image}
                        draggable="false"
                        alt=""
                     />
                  </div>
                  <div className={styles.strategy__content}>
                     <h3 className={styles.strategy__title}>Analyze</h3>
                     <p>
                        An examination of financial, tax, and estate documents,
                        along with risk assessment and retirement aspirations
                        will be explored.
                     </p>
                  </div>
               </div>
               <div className={styles.strategy}>
                  <div className={styles.strategy__image_container}>
                     <img
                        src={strategyDevelop}
                        className={styles.strategy__image}
                        draggable="false"
                        alt=""
                     />
                  </div>
                  <div className={styles.strategy__content}>
                     <h3 className={styles.strategy__title}>Develop</h3>
                     <p>
                        A customized, tax integrated, wealth management plan
                        will be designed to help meet your individual financial
                        objectives.
                     </p>
                  </div>
               </div>
               <div className={styles.strategy}>
                  <div className={styles.strategy__image_container}>
                     <img
                        src={strategyImplement}
                        className={styles.strategy__image}
                        draggable="false"
                        alt=""
                     />
                  </div>
                  <div className={styles.strategy__content}>
                     <h3 className={styles.strategy__title}>Implement</h3>
                     <p>
                        Our financial planning team and investment analysts will
                        construct a suitable timetable for integrating all
                        aspects of a mutually agreed upon financial plan.
                     </p>
                  </div>
               </div>
               <div className={styles.strategy}>
                  <div className={styles.strategy__image_container}>
                     <img
                        src={strategyMonitor}
                        className={styles.strategy__image}
                        draggable="false"
                        alt=""
                     />
                  </div>
                  <div className={styles.strategy__content}>
                     <h3 className={styles.strategy__title}>Monitor</h3>
                     <p>
                        Each investment advisory team will routinely conduct a
                        comprehensive portfolio assessment + its impact on
                        changes to tax laws, investment/financial environments,
                        and employment status.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section
         className={[styles.section, styles.section__facts].join(' ')}
         id="facts">
         <div className={styles.facts__content}>
            <div className={styles.facts__fact}>
               <div className={styles.fact__icon}>
                  <img
                     src={familiesAssisted}
                     className={styles.stats_image}
                     draggable="false"
                     alt=""
                  />
               </div>
               <h3 className={styles.fact__figure}>2,000+</h3>
               <p className={styles.fact__description}>Families Assisted</p>
            </div>
            <div className={styles.facts__fact}>
               <div className={styles.fact__icon}>
                  <img
                     src={percentage}
                     className={styles.stats_image}
                     draggable="false"
                     alt=""
                  />
               </div>
               <h3 className={styles.fact__figure}>$1.4 billion</h3>
               <p className={styles.fact__description}>
                  Assets Under Advisement + Management
                  <sup>
                     <Link to="/legal/disclaimers#aum">1</Link>
                  </sup>
               </p>
            </div>
            <div className={styles.facts__fact}>
               <div className={styles.fact__icon}>
                  <img
                     src={assets}
                     className={styles.stats_image}
                     draggable="false"
                     alt=""
                  />{' '}
               </div>
               <h3 className={styles.fact__figure}>38%</h3>
               <p className={styles.fact__description}>
                  of clients utilize financial and tax services
                  <Link to="/legal/disclaimers#tax">*</Link>
                  <sup>
                     <Link to="/legal/disclaimers#dual-clients">2</Link>
                  </sup>
               </p>
            </div>
         </div>
      </section>

      <section
         className={[styles.modern__banner, styles.section__EGSAwards].join(
            ' '
         )}>
         <div className={styles.photo}>
            <img
               src="//images.ctfassets.net/wt3nvc89w481/3CcMMiOTloiErSPpV8Og16/cbed1bf6450c853f6f7e79e1449dca71/Edward_Sella.jpg?w=400&h=400&q=50"
               draggable="false"
               alt=""
            />
         </div>

         <div className={styles.meta_info}>
            <div className={styles.content}>
               <h2 className={styles.name}>Edward G. Sella</h2>
               <p className={styles.title}>
                  President & CEO / Financial Advisor
               </p>
            </div>
            <div className={styles.view_bio}>
               <Link
                  to="/team/edward-sella"
                  className="button button--primary button--small button--has-icon">
                  Bio <span>&rarr;</span>
               </Link>
            </div>
         </div>

         <div className={styles.text_content}>
            <p>
               <b>President & CEO</b>, <b>Edward G. Sella</b> has been honored
               in numerous rankings for his continued perseverance and
               dedication by providing human-centric financial services to
               address the individual challenges of our diverse investment
               family.
            </p>
            <div className={styles.centered}>
               <Link
                  to="/rankings/"
                  className="button button--primary button--bordered button--has-icon">
                  View Our Showcase <span>&rarr;</span>
               </Link>
               <img src={separator} draggable="false" alt="" />
            </div>
         </div>

         {data.allContentfulAward.edges.map(({ node }, index) => (
            <React.Fragment key={index}>
               <div className={styles.award} data-key={'award' + (index + 1)}>
                  <h2>{node.title}</h2>
               </div>
               <div className={styles.award_background}>
                  &nbsp;
                  <div className={styles.criteria_button}>
                     <Link
                        to="/rankings/"
                        className="button button--secondary button--xtra-small">
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                     </Link>
                     <Link
                        to="/rankings/"
                        className="button button--secondary button--bordered button--xtra-small">
                        Criteria
                     </Link>
                  </div>
                  {(node.source.toLowerCase().replace(/[^\w]/gi, '') ===
                     'barrons' ||
                     node.source.toLowerCase().replace(/[^\w]/gi, '') ===
                        'financialtimes' ||
                     node.source.toLowerCase().replace(/[^\w]/gi, '') ===
                        'forbes') && (
                     <div className={styles.source}>
                        <img
                           src={
                              node.source
                                 .toLowerCase()
                                 .replace(/[^\w]/gi, '') === 'barrons'
                                 ? barrons
                                 : node.source
                                      .toLowerCase()
                                      .replace(/[^\w]/gi, '') ===
                                   'financialtimes'
                                 ? financialtimes
                                 : node.source
                                      .toLowerCase()
                                      .replace(/[^\w]/gi, '') === 'forbes'
                                 ? forbes
                                 : ''
                           }
                           draggable="false"
                           alt={node.source
                              .toLowerCase()
                              .replace(/[^\w\s]/gi, '')}
                        />
                     </div>
                  )}
               </div>
            </React.Fragment>
         ))}
      </section>

      <section
         className={['section', styles.section, styles.section__services].join(
            ' '
         )}>
         <div className={['section--header', styles.section__header].join(' ')}>
            <h2 className={['section--title', styles.section__title].join(' ')}>
               Our Services
            </h2>
            <p
               className={[
                  'section--subheader',
                  styles.section__subheader,
               ].join(' ')}>
               We integrate each financial portfolio with an effective
               human-centric estate + tax plan, designed to help maximize an
               individual's wealth potential.
            </p>
         </div>
         <div className={styles.services__content}>
            <Service
               type="mini"
               image={data.wealthPreservation.childImageSharp.fluid}
               name="Wealth Preservation"
               link="/wealth-preservation/"
               description="Strategies and techniques for preserving wealth for community, spouses, and heirs."
            />

            <Service
               type="mini"
               image={data.collegeFunding.childImageSharp.fluid}
               name="College Funding"
               description="Utilizing different methods for accumulating funds and paying for college expenses."
            />

            <Service
               type="mini"
               image={data.portfolioManagement.childImageSharp.fluid}
               name="Portfolio Management"
               description="Within a portfolio allocation, specific decisions need to be made to help manage risk, income tax liability, and other financial considerations."
            />

            <Service
               type="mini"
               image={data.incomeTaxPlanning.childImageSharp.fluid}
               name="Income Tax Planning"
               description="Tax laws evolve every year. It is important to ensure that your income tax planning incorporates these changes.*"
            />

            <Service
               type="mini"
               image={data.estateTaxPlanning.childImageSharp.fluid}
               name="Estate Tax Planning"
               description="Various federal and State taxes may be applicable at death. In addition, there can be non-tax considerations for developing an Estate plan including the potential loss of government benefits by an heir; the need for guardianship provisions; the desire to protect a spouse and the goal of protecting an inheritance from creditor claims."
            />

            <Service
               type="mini"
               image={data.retirementPlanning.childImageSharp.fluid}
               name="Retirement Planning"
               description="There are two important phases: accumulation & distribution. During the accumulation phase, it is important to maximize the available tax incentives. During the distribution phase, it is important to consider potential tax consequences and/or Medicare premiums."
            />

            <Service
               type="mini"
               image={data.riskManagement.childImageSharp.fluid}
               name="Risk Management"
               description="There are multiple risks. These risks can either be assumed or transferred to a third party. Risks that could have a significant impact should be transferred."
            />

            <Service
               type="mini"
               image={data.secondOpinion.childImageSharp.fluid}
               name="Second Opinion"
               description="We are happy to review existing portfolios for clients and their family, friends, and associates."
            />
         </div>
         <div className={styles.section__cta}>
            <Link
               to="/services/"
               className="button button--secondary button--large button--rounded button--has-icon">
               All Services <span>&rarr;</span>
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link
               to="/contact?service=true"
               className="button button--secondary button--bordered button--large button--rounded button--has-icon">
               Contact Us <span>&rarr;</span>
            </Link>
         </div>
      </section>

      <section
         className={[styles.section, styles.section__facts].join(' ')}
         id="facts2">
         <div className={styles.facts__content}>
            <div className={styles.facts__fact}>
               <div className={styles.fact__icon}>
                  <img
                     src={user}
                     className={styles.stats_image}
                     draggable="false"
                     alt=""
                  />
               </div>
               <h3 className={styles.fact__figure}>31</h3>
               <p className={styles.fact__description}>
                  Human-Centric Team Members
               </p>
            </div>
            <div className={styles.facts__fact}>
               <div className={styles.fact__icon}>
                  <img
                     src={certification}
                     className={styles.stats_image}
                     draggable="false"
                     alt=""
                  />{' '}
               </div>
               <h3 className={styles.fact__figure}>21</h3>
               <p className={styles.fact__description}>
                  Specialized Certifications Across Our Team
               </p>
            </div>
            <div className={styles.facts__fact}>
               <div className={styles.fact__icon}>
                  <img
                     src={spc}
                     className={styles.stats_image}
                     draggable="false"
                     alt=""
                  />{' '}
               </div>
               <h3 className={styles.fact__figure}>SPC</h3>
               <p className={styles.fact__description}>
                  participates in community outreach programs
               </p>
            </div>
         </div>
      </section>

      <section
         className={['section', styles.section, styles.section__team].join(' ')}
         id="advisors">
         <div className={['section--header', styles.section__header].join(' ')}>
            <h2 className={['section--title', styles.section__title].join(' ')}>
               Our Advisors
            </h2>
            <p
               className={[
                  'section--subheader',
                  styles.section__subheader,
               ].join(' ')}>
               We encourage you to meet our human-centric team of wealth
               management and tax-integrated advisors.
               <Link to="/legal/disclaimers#tax">*</Link>
            </p>
         </div>
         <div className={stylesTeam.team__members}>
            {data.allContentfulEmployee.edges.map(({ node }, index) => (
               <TeamMember
                  key={index}
                  name={node.name}
                  nickname={node.nickname}
                  slug={node.slug}
                  imageFluid={node.image?.fluid}
                  imageTitle={node.image?.title}
                  certifications={node.certifications}
                  roleSpc={node.roleSpc}
                  roleSm={node.roleSm}
                  hasBiography={node.biography.id ? true : false}
                  linkedIn={node.linkedIn}
               />
            ))}
         </div>
         <div className={styles.section__cta}>
            <Link
               to="/team/"
               className="button button--primary button--large button--rounded button--has-icon">
               Meet Our Team <span>&rarr;</span>
            </Link>
         </div>
      </section>

      <section className={[styles.section, styles.section__contact].join(' ')}>
         <div className={['section--header', styles.section__header].join(' ')}>
            <h2 className={['section--title', styles.section__title].join(' ')}>
               Let's Connect
            </h2>
            <p
               className={[
                  'section--subheader',
                  styles.section__subheader,
               ].join(' ')}>
               We are happy to assist with any questions or inquiry you may have
               concerning your financial needs.
            </p>
         </div>
         <div className={styles.contact__content}>
            <Contact page="home" />
         </div>
      </section>

      <section className={[styles.section, styles.section__banner].join(' ')}>
         <Img
            fluid={data.file.childImageSharp.fluid}
            className={styles.image}
         />
      </section>
   </Layout>
)

export default IndexPage

export const squareImage = graphql`
   fragment squareImage on File {
      childImageSharp {
         fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_tracedSVG
         }
      }
   }
`

export const query = graphql`
   query {
      backgroundImage: file(relativePath: { eq: "images/banner.png" }) {
         childImageSharp {
            fluid {
               ...GatsbyImageSharpFluid
            }
         }
      }

      wealthPreservation: file(
         relativePath: { eq: "images/services/wealth-preservation.png" }
      ) {
         ...squareImage
      }

      collegeFunding: file(
         relativePath: { eq: "images/services/college-funding.png" }
      ) {
         ...squareImage
      }

      portfolioManagement: file(
         relativePath: { eq: "images/services/portfolio-management.png" }
      ) {
         ...squareImage
      }

      incomeTaxPlanning: file(
         relativePath: { eq: "images/services/income-tax-planning.png" }
      ) {
         ...squareImage
      }

      estateTaxPlanning: file(
         relativePath: { eq: "images/services/estate-tax-planning.png" }
      ) {
         ...squareImage
      }

      retirementPlanning: file(
         relativePath: { eq: "images/services/retirement-planning.png" }
      ) {
         ...squareImage
      }

      riskManagement: file(
         relativePath: { eq: "images/services/risk-management.png" }
      ) {
         ...squareImage
      }

      secondOpinion: file(
         relativePath: { eq: "images/services/second-opinion.png" }
      ) {
         ...squareImage
      }

      allContentfulAward(
         limit: 3
         filter: { node_locale: { eq: "en-US" } }
         sort: { fields: [yearAwarded, dates], order: DESC }
      ) {
         edges {
            node {
               title
               source
            }
         }
      }

      allContentfulEmployee(
         filter: { node_locale: { eq: "en-US" }, teams: { eq: "Advisors" } }
         sort: { fields: [yearJoined], order: ASC }
      ) {
         edges {
            node {
               name
               slug
               nickname
               linkedIn
               certifications
               yearJoined
               image {
                  title
                  fluid(maxWidth: 300) {
                     ...GatsbyContentfulFluid
                  }
               }
               roleSpc
               roleSm
               teams
               biography {
                  id
               }
            }
         }
      }
      file(relativePath: { eq: "images/banner.png" }) {
         childImageSharp {
            fluid(maxWidth: 1920) {
               ...GatsbyImageSharpFluid
            }
         }
      }
   }
`
